* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Libre Franklin", sans-serif;
  scroll-behavior: smooth;
}

body {
  /* background: #f0f0f0; */
  background: white;
}

a {
  color: unset;
  text-decoration: none;
}

/* .redux-toastr {
  width: 1000px !important;
  height: 100px !important
} */
